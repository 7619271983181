.generia {

  .component-carrousel {
    .slick-next, .slick-prev {
      top: 57%;
    }
  }

  .slick-next {
    right: 44px;
    z-index: 10;
    background-image: url(../assets/arrow-slider.png);
    background-repeat: no-repeat;
    width: 15px;
    height: 25px;
    background-size: contain;
    transform: translate(0%,-50%) rotate(180deg);

    @media(max-width: 1024px) {
      right: 32px;
    }
    @media(max-width: 600px) {
      right: 7px;
    }

    &:before {
      content: none;
    }

  }

  .slick-prev {
    left: 35px;
    z-index: 10;
    background-image: url(../assets/arrow-slider.png);
    background-repeat: no-repeat;
    width: 15px;
    height: 25px;
    background-size: contain;
    transform: translate(50%,-50%);

    @media(max-width: 1024px) {
      left: 24px;
    }
    @media(max-width: 600px) {
      left: -1px;
    }

    &:before {
      content: none;
    }

  }

  .slick-prev, .slick-next {
    //transform: none;
    z-index: 20;
  }

  .slick-next:hover, .slick-next:focus {
    background-image: url(../assets/arrow-slider.png);
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.4;

  }
  .slick-prev:hover, .slick-prev:focus {
    background-image: url(../assets/arrow-slider.png);
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.4;
  }


  .slick-prev:before, .slick-next:before {
    font-size: 50px;
  }


  .component-carrousel {

    .slick-slider.center {
      width: 100% !important;
      //cursor: pointer;

      .slick-track {
        height: 300px;
      }

      .slick-slide {

        img {
          object-fit: cover;
          transition: all 0.6s ease;
        }

        * {
          height: 100% !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:not(.slick-active) {
          img {
            height: 85% !important;
            transition: all 0.4s ease;
          }
        }

        &.slick-active {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          box-sizing: border-box;
          cursor:pointer;

        }
      }


    }

  }
}
