@font-face {
  font-family: 'icomoon-generia';
  src:  url('icomoon-generia.eot?xmo4rz');
  src:  url('icomoon-generia.eot?xmo4rz#iefix') format('embedded-opentype'),
  url('icomoon-generia.ttf?xmo4rz') format('truetype'),
  url('icomoon-generia.woff?xmo4rz') format('woff'),
  url('icomoon-generia.svg?xmo4rz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-generia-"], [class*=" ico-generia-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-generia' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-generia-lu:before {
  content: "\e906";
}
.ico-generia-non_lu:before {
  content: "\e908";
}
.ico-generia-time:before {
  content: "\e90b";
}
.ico-generia-close:before {
  content: "\e90c";
}
.ico-generia-burger:before {
  content: "\e900";
}
.ico-generia-chevron-down:before {
  content: "\e901";
}
.ico-generia-chevron-left:before {
  content: "\e902";
}
.ico-generia-chevron-right:before {
  content: "\e903";
}
.ico-generia-chevron-up:before {
  content: "\e904";
}
.ico-generia-next-article:before {
  content: "\e905";
}
.ico-generia-prev-article:before {
  content: "\e907";
}
.ico-generia-search:before {
  content: "\e909";
}
.ico-generia-reading:before, .ico-generia-start-reading:before {
  content: "\e90a";
}
